import React from 'react';

import Icon from '../../Common/Icon';
import * as styleConstants from '../../../styles/style';
import I18n from '../../../language/i18n';

type TSecondaryTabBarProps = {
  show: boolean;
  onClose: (...args: any) => void;
  onSelectCropColorClick: (...args: any) => void;
  onSelectProducersClick: (...args: any) => void;
  onSelectHarvestClick: (...args: any) => void;
  onSelectArchiveClick: (...args: any) => void;
  items: any[];
};

const SecondaryTabBar = ({
  show,
  items,
  onClose,
  onSelectCropColorClick,
  onSelectProducersClick,
  onSelectHarvestClick,
  onSelectArchiveClick,
}: TSecondaryTabBarProps) => {

  if (!show) {
    return null;
  }

  return (
    <div className="toolbar-container" style={{ display: 'flex', alignItems: 'center' }}>
      <div
        onClick={onClose}
      >
        <Icon
          iconType="fa"
          name="times"
          style={{ fontSize: 18, cursor: 'pointer', color: styleConstants.FJMUTEDLIGHT }}
        />
      </div>
      <div>
        {`${items.length} ${I18n.t('selected')}`}
      </div>
      <button
        className="btn btn-secondary column-config-button"
        onClick={onSelectCropColorClick}
      >
        {I18n.t('colors.change')}
      </button>
      <button
        className="btn btn-secondary column-config-button"
        onClick={onSelectProducersClick}
      >
        {I18n.t('producers.change')}
      </button>
      <button
        className="btn btn-secondary column-config-button"
        onClick={onSelectHarvestClick}
      >
        {I18n.t('crop.harvest')}
      </button>
      <button
        className="btn btn-secondary column-config-button"
        onClick={onSelectArchiveClick}
      >
        {I18n.t('archive.toArchive')}
      </button>
    </div>
  );
};

export default SecondaryTabBar;
