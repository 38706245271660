import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addComment } from 'farmerjoe-common/lib/actions/comment';
import { updateField } from 'farmerjoe-common/lib/actions/field';

import {
  AlertConfirmDialog,
} from '../../../Dialog/Dialog';
import I18n from '../../../../language/i18n';

import { UserObj } from 'farmerjoe-common/lib/utils/User';
import { FieldState } from 'farmerjoe-common/lib/flow/types';

import firebase from '../../../../data/firebase';

import { dummy } from '../../../../utils/Comment';

type TProps = {
  show: boolean;
  selectedFields: string[];
  fields: {
    key: string;
    company_id: string;
    activeCrop: any;
  }[];
  onClose: (...args: any) => void;
  actions: {
    updateField: typeof updateField;
    addComment: typeof addComment;
  };
};

const ArchiveFieldsDialog = (props: TProps) => {

  const selected = props.fields.filter((field) =>
    props.selectedFields.includes(field.key),
  );

  const onConfirm = () => {
    const auth = firebase.auth().currentUser;

    for (const field of selected) {
      const updatedField = {
        ...field,
        modified: moment().toDate(),
        modified_by: UserObj(auth as any),
        state: FieldState.Archived,

      };
      props.actions.updateField(updatedField as any);
      const comment = {
        ...dummy(
          null as any,
          field.company_id as any,
          field as any,
          field.activeCrop,
          auth as any,
          'system.archive',
        ),
        text: { languageConstant: 'field.movedToArchive' },
      };

      props.actions.addComment(comment);
    }
  };

  return (
    <AlertConfirmDialog
      show={props.show}
      title={I18n.t('archive.modalTitle')}
      onClose={result => {
        if (result === 'yes') {
          onConfirm();
        }
        props.onClose();
      }}
    >
      <span> {I18n.t('archive.modalDescription')} </span>
    </AlertConfirmDialog>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          updateField,
          addComment,
        },
      ),
      dispatch,
    ),
  };
};

export default connect(null, mapDispatchToProps)(ArchiveFieldsDialog);
