import React, { PureComponent } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { get } from 'lodash-es';

import {
  getCompanyGroupProfileForLoggedInUser,
} from 'farmerjoe-common/lib/selectors/user';
import { editField, openField } from 'farmerjoe-common/lib/actions/field';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import {
  searchForFieldAmongTheUniverse,
  getViewForField,
} from 'farmerjoe-common/lib/selectors/fields';
import { getCropByFieldId, getCrops, getCrop } from 'farmerjoe-common/lib/selectors/crops';
import { canDo, isAdmin } from 'farmerjoe-common/lib/utils/User';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { getFieldQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Fields';
import {
  getCropsForFieldQuery,
  getCropQuery,
} from 'farmerjoe-common/lib/utils/firestoreRedux/Crops';
import { fieldToMarker } from 'farmerjoe-common';
import { getUnreadNotificationsQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Notifications';
import { getFieldNotifications } from 'farmerjoe-common/lib/selectors/notifications';
import { markNotificationsAsRead } from 'farmerjoe-common/lib/actions/notifications';
import { FieldState, NotACropState, CropState } from 'farmerjoe-common/lib/flow/types';
import { getFormSubmissionsForFieldQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Forms';
import { isAbandonedField, isSharedField } from 'farmerjoe-common/lib/utils/Field';
import { runLoader } from 'farmerjoe-common/lib/actions/ui';

import CreateField from './CreateField';
import FieldDropzone from './FieldDropzone';
import FieldInfo from './FieldInfo';
import Footer from './Footer';
import Navbar from './Navbar';
import Icon from '../Common/Icon';
import { EditButton } from '../Common/EditButton';
import Comments from '../Comments/Comments';
import ImageCommentMap from '../Map/ImageCommentMap';
import withRouter from '../Router/withRouter';
import { Loading } from '../Loading/Loading';
import I18n from '../../language/i18n';
import { findAncestor } from '../../utils/dom';
import { getFieldPath, isFieldPage } from '../../utils/page';

import * as constants from '../../styles/style';
import './style.css';

const bodyContainerStyle = {
  ...constants.styles.containerColumn,
  justifyContent: 'flex-end',
};

// TODO: improve typings
type Props = any;
type State = any;

class Field extends PureComponent<Props, State> {
  private needsToMarkNotificationsAsRead?: boolean;
  private openedCrop?: boolean;

  // Initialize the hardcoded data
  constructor(props, context) {
    super(props, context);

    this.state = {
      behavior: 'padding',
      showFieldInfo: false,
      border: false,
      show: true,
      showMap: false,
      showEditForm: false,
    };
  }

  componentWillUpdate(nextProps) {
    this.openCrop(nextProps);

    if (
      this.state.showMap &&
      get(this.props, 'field.key') !== get(nextProps, 'field.key')
    ) {
      this.setState({ showMap: false });
    }
  }

  componentDidMount() {
    this.openCrop();
    this.markNotificationsAsRead();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.notifications.length !== prevProps.notifications.length) {
      this.needsToMarkNotificationsAsRead = true;
    }

    // we need to check if we're on the right page because of cached routes
    if (
      this.needsToMarkNotificationsAsRead &&
      isFieldPage(undefined, this.props.companyId, this.props.fieldId)
    ) {
      this.markNotificationsAsRead();
    }

    // XXX: When accepting a shared field, there are some serverless functions
    // that are preparing the system, which needs to finish first before we
    // open the field. Thus, we run a loader, and once the field is ready,
    // we close it.
    if (this.props.field && this.props.uiLoader && this.props.uiLoader.isRunning) {
      this.props.actions.runLoader(false);
    }
  }

  markNotificationsAsRead() {
    if (this.props.notifications.length) {
      this.props.actions.markNotificationsAsRead(this.props.notifications);
    }

    this.needsToMarkNotificationsAsRead = false;
  }

  openCrop(props = this.props) {
    if (
      !this.openedCrop &&
      (props.fieldId !== props.openField ||
        props.harvestedCropId !== props.openCrop)
    ) {
      this.openedCrop = true;
      props.actions.openField(props.fieldId, props.harvestedCropId);
    }
  }

  // TODO: this needs to be refactored!!! It's way too complicated
  render() {
    let {
      field,
      activeCrops,
      loading,
      companyId,
      myCompanyProfile,
      isFloating,
      isMapPage,
      uiLoader,
    } = this.props;

    if (uiLoader && uiLoader.isRunning) {
      return <Loading />;
    }

    // Well, this is a hack, but we will live with it for now
    // If you delete the field, this scene is still mounted and react tries to update it
    // but now we don't have the field anymore and this will cause errors later when we try to access
    // properties of the field
    if (!field) {
      return null;
    }

    let activeCrop = field.activeCrop;

    if (!activeCrop) {
      return null;
    }

    // not_a_crop = 0 - crop is planted
    // not_a_crop = 2 - crop is planned
    const canEdit =
      isAdmin(myCompanyProfile) ||
      canDo('edit', 'field', myCompanyProfile) ||
      (canDo('create_edit', 'crop', myCompanyProfile) &&
        (activeCrop.not_a_crop === NotACropState.HarvestedCrop || activeCrop.not_a_crop === NotACropState.PlannedCrop));

    let noCrop = false;
    // Only set the harvested crop when it exist
    // If we delete the crop when looking at harvested crops, then we are in big trouble without this
    if (this.props.showingHarvested) {
      if (get(this.props.activeCrops, this.props.harvestedCropId)) {
        activeCrop = this.props.activeCrops[this.props.harvestedCropId];
        field = {
          ...field,
          activeCrop: activeCrop,
        };
      } else {
        noCrop = true;
      }
    }

    let marker = fieldToMarker(field);
    const partialCrop = get(field, 'activeCrop.markedArea', null);
    if (partialCrop) {
      marker = {
        ...marker,
        position: partialCrop.center,
      };
    }

    let body = (
      <div style={bodyContainerStyle}>
        {loading ? (
          <Loading />
        ) : this.state.showMap ? (
          <ImageCommentMap
            markers={[marker]}
            position={field.position}
            zoomedIn={true}
            displayFilter={false}
            hideSearch={true}
            containerStyle={{ height: '100%' }}
            hideResetFilter={true}
            ignoreFilter={true}
            field={field}
          />
        ) : (
          <Comments
            allActiveCrops={activeCrops}
            field={field}
            myCompanyProfile={myCompanyProfile}
          />
        )}

        {!this.state.showMap
          ? (
            <Footer
              field={field}
              activeCrops={activeCrops}
              myCompanyProfile={myCompanyProfile}
            />
          )
          : null}
      </div>
    );

    if (noCrop) {
      body = (
        <div style={constants.styles.containerColumn}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <span>{I18n.t('crop.cropDoesntExist')}</span>
          </div>
        </div>
      );
    }

    const container = (
      <div className={'scrollable-content'}>
        <FieldInfo
          field={field}
          open={this.state.showFieldInfo}
          onClick={() => {
            this.setState({ showFieldInfo: !this.state.showFieldInfo });
          }}
          myCompanyProfile={myCompanyProfile}
        />

        {body}
      </div>
    );

    return (
      <div className="field">
        <FieldDropzone
          field={field}
          noLoading={false}
          disabled={this.state.showMap}>
          <Navbar
            onClick={() => {
              this.setState({ showFieldInfo: !this.state.showFieldInfo });
            }}
            leftButton={
              isFloating ? (
                <div
                  className="top-bar-button gray-text"
                  onClick={(e: any) => {
                    // button is only used for closing the field
                    const column = findAncestor(e.target, '.sliding-column') as any;
                    if (column) {
                      const classList = column.classList;
                      classList.remove('open');
                    }
                    this.props.actions.openField(null);
                    this.props.history.push(getFieldPath(companyId));
                  }}>
                  <Icon iconType="fa" name="times" style={{ fontSize: 25 }} />
                </div>
              ) : null
            }
            rightButton={
              <div className={'grouped-buttons'}>
                {!isMapPage
                  ? (
                    <div
                      className="top-bar-button gray-text"
                      onClick={() => {
                        this.setState({ showMap: !this.state.showMap });
                      }}>
                      <Icon
                        iconType={this.state.showMap ? 'fa' : 'fal'}
                        name="map"
                        style={{ fontSize: 25 }}
                      />
                    </div>
                  )
                  : null}

                {!canEdit || field.state === FieldState.Archived || isAbandonedField(field.state)
                  ? null
                  : (
                    <EditButton
                      onClick={() => {
                        this.props.actions.editField(field.key);
                        this.setState({ showEditForm: true });
                      }}
                    />
                  )}
              </div>
            }
            field={field}
          />
          {container}
        </FieldDropzone>

        {this.state.showEditForm
          ? (
            <CreateField
              show={this.state.showEditForm}
              onClose={() => this.setState({ showEditForm: false })}
              field={field}
            />
          )
          : null}
      </div>
    );
  }
}

const selector = (state, ownProps) => {
  const { fieldId, companyId, isFloating } = ownProps;
  const uiLoader = get(state, 'uiLoader');
  const user = state.firebase.profile;
  const openCompanyId = companyId || selectors.getOpenCompanyId(state);
  const currentFieldId = fieldId || selectors.getOpenFieldId(state);
  const currentCropId = selectors.getOpenCropId(state);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompanyId,
  );

  const browsingGroup = getBrowsingGroupKey(state, openCompanyId);
  const notifications = getFieldNotifications(
    state,
    state.firebase.profile.uid,
    openCompanyId,
    currentFieldId,
  );

  let field = searchForFieldAmongTheUniverse(state, openCompanyId, user.uid, currentFieldId);

  const crop = getCropByFieldId(
    state.firestore.data,
    openCompanyId,
    currentFieldId,
    currentCropId,
  );

  if (field) {
    const fieldIdForView = isAbandonedField(field.state)
      ? field.realFieldId
      : currentFieldId;
    const cropIdForView = isAbandonedField(field.state)
      ? get(field, 'activeCrop.realCropId')
      : currentFieldId;

    const fieldView = getViewForField(state, openCompanyId, fieldIdForView);

    if (fieldView) {
      const activeCropFromView = get(
        fieldView,
        `activeCrops[${cropIdForView}]`,
        {},
      );

      const notModifiedCrop = field.activeCrop;
      field = {
        ...field,
        activeCrop: {
          ...notModifiedCrop,
          ...activeCropFromView,
        },
      };
      const { activeCrops, ...rest } = field; // drop activeCrops;
      field = { ...rest };
    }
  }


  let crops = {};
  const fieldIsShared = field
    ? isSharedField(field.company_id, openCompanyId)
    : false;
  const isHarvestedCrop = crop ? crop.state === CropState.Harvested : false;

  if (fieldIsShared) {
    if (isHarvestedCrop) {
      crops = { [currentCropId]: crop };
    }
    const _crop = getCrop(
      state.firestore.data,
      openCompanyId,
      currentFieldId,
      field.activeCrop.key,
    );
    if (_crop) {
      crops = { [field.activeCrop.key]: _crop };
    }
  } else {
    if (isHarvestedCrop) {
      crops = { [currentCropId]: crop };
    } else {
      crops = getCrops(state, openCompanyId, currentFieldId);
    }
  }

  const queries: any[] = [];

  if (currentFieldId) {
    queries.push(getFieldQuery(openCompanyId, currentFieldId));
  }
  queries.push(getUnreadNotificationsQuery(user.uid));

  if (field) {
    if (isSharedField(field.company_id, openCompanyId)) {
      queries.push(
        getCropQuery(field.activeCrop.key),
      );
    } else {
      queries.push(
        getCropsForFieldQuery(
          openCompanyId,
          field,
          browsingGroup,
          myCompanyProfile,
        ),
      );
    }

    queries.push(
      getFormSubmissionsForFieldQuery(
        field.company_id,
        browsingGroup,
        field.key,
        field.activeCrop.key,
      ),
    );
  }

  return {
    myCompanyProfile,
    openField: currentFieldId,
    openCrop: currentCropId,
    companyId: openCompanyId,
    field,
    activeCrops: crops,
    showingHarvested: !!ownProps.crop_id,
    harvestedCropId: ownProps.crop_id,
    loading: !hasLoaded(queries, state),
    browsingGroup,
    isFloating,
    notifications,
    pathname: ownProps.location.pathname, // to force rerender
    user,
    uiLoader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        openField,
        editField,
        markNotificationsAsRead,
        runLoader,
      },
      dispatch,
    ),
  };
};

export default compose<typeof Field>(
  withRouter,
  connect(
    selector,
    mapDispatchToProps,
  ),
  firestoreConnect(props => {
    const {
      myCompanyProfile,
      field,
      openField: currentFieldId,
      browsingGroup,
      companyId,
      user,
    } = props;
    const queries: any[] = [];

    // Make sure that we don't present a white screen when we create a field and it is not in the collection
    // of fields
    if (!currentFieldId) {
      return [];
    }

    queries.push(getUnreadNotificationsQuery(user.uid));
    queries.push(getFieldQuery(companyId, currentFieldId));

    if (field) {

      // If the field is shared, we need to load only the current crop
      if (isSharedField(field.company_id, companyId)) { // TODO: use the helper function
        queries.push(
          getCropQuery(
            field.activeCrop.key,
          ),
        );
      } else {
        queries.push(
          getCropsForFieldQuery(
            field.company_id,
            field,
            browsingGroup,
            myCompanyProfile,
          ),
        );
      }

      queries.push(getFormSubmissionsForFieldQuery(
        field.company_id,
        browsingGroup,
        field.key,
        field.activeCrop.key,
      ));
    }

    return queries;
  }),
)(Field);
